<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນເອກະສານຂາອອກ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ເພີ້ມຂາອອກ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #actions="row">
                <td>
                  <CButton color="info" size="sm" v-on:click="onClickView(row.item)">
                    <CIcon name="cil-file" />
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <!-- modal create new document out -->
    <CModal
      title="ຟອມເພີ້ມຂໍ້ມຸນເອກະສານຂາອອກ"
      size="lg"
      :show.sync="modalCreate"
      :closeOnBackdrop="false"
    >
      <documentFormCreate @on_success="onSubmitCreate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import { getDocOUTAPI, createDocOUTAPI } from "@/api/documentOut.js";
import documentFormCreate from "./components/DocumentFormCreate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "DocumentOut",
  components: {
    documentFormCreate,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      items: [],
      fields: [
        { key: "doc_info_id", label: "ເລກທີ" },
        { key: "doc_date", label: "ວັນທີ" },
        { key: "doc_name", label: "ຫົວຂໍ້ເອກະສານ" },
        { key: "doc_detail", label: "ລາຍລະອຽດຂອງເອກະສານ" },
        { key: "doc_form", label: "ມາຈາກ" },
        { key: "doc_create_at", label: "ຜູ້ບັນທືກ" },
        { key: "actions", label: "ຈັດການ" },
      ],
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    // Load data  document income
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getDocOUTAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
    // Create New document income
    onSubmitCreate(credentials) {
      createDocOUTAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },
    onClickView(item) {
      window.open(
        window.location.origin +
          "/api_3c_link/uploaded/document/" +
          item.doc_scan_path,
        "_blank"
      );
    },
  },
};
</script>
