import axios from '@/libs/api.request'

export const getDocOUTAPI = () => {
    return axios.request({
        url: 'document-out',
        method: 'get'
    })
}

export const getDocOUTByIdAPI = (id) => {
    return axios.request({
        url: 'document-out/' + id,
        method: 'get'
    })
}

export const createDocOUTAPI = (credentials) => {
    return axios.request({
        url: 'document-out',
        data: credentials,
        method: 'post'
    })
}

export const updateDocOUTAPI = (credentials) => {
    return axios.request({
        url: 'document-out/' + credentials.doc_id,
        data: credentials,
        method: 'put'
    })
}



