<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ເລກທີເອກະສານ :"
              prop="doc_info_id"
              v-model="form.doc_info_id"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required">
            <div class="form-group">
              <label>ວັນທີ :</label>
              <date-picker :config="options" prop="doc_date" v-model="form.doc_date"></date-picker>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput
              class="is-invalid"
              label="ຫົວຂໍ້ເອກະສານ :"
              prop="doc_name"
              v-model="form.doc_name"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="12">
          <CTextarea label="ລາຍລະອຽດ :" prop="doc_detail" v-model="form.doc_detail" rows="5" />
        </CCol>
        <CCol sm="6">
          <CInput label="ອອກໄປພາກສ່ວນ :" prop="doc_form" v-model="form.doc_form" />
        </CCol>
        <CCol sm="6">
          <div class="form-group">
            <label for="file">ໄຟລ໌ເອກະສານ :</label>
            <input
              type="file"
              class="form-control-file"
              id="file"
              name="file"
              ref="file"
              @change="handleFileUpload"
              accept=".pdf"
            />
          </div>
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="primary"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "DocumentOutFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form: {
        doc_info_id: "",
        doc_date: "",
        doc_name: "",
        doc_detail: "",
        doc_form: "",
        file: null,
      },
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
    };
  },
  methods: {
    handleFileUpload() {
      let selectFile = this.$refs.file.files[0];

      let reader = new FileReader();

      // check size file
      if (selectFile["size"] < 21000000) {
        // check sign name

        let checkFile = selectFile.name
          .toString()
          .substring(selectFile.name.length - 3, selectFile.name.length);
        if (checkFile == "pdf" || checkFile == "PDF") {
          reader.onloadend = (selectFile) => {
            this.form.file = reader.result;
          };
          reader.readAsDataURL(selectFile);
        } else {
          this.$awn.alert("please select pdf file only");
        }
      } else {
        this.$awn.alert("please select file smaller than 20 MB");
      }
    },
    handleSubmit() {
      this.$emit("on_success", {
        doc_info_id: this.form.doc_info_id,
        doc_date: this.form.doc_date,
        doc_name: this.form.doc_name,
        doc_detail: this.form.doc_detail,
        doc_form: this.form.doc_form,
        file: this.form.file,
        doc_create_at: decrypt(this.$store.getters.getFullName),
      });
      this.form.doc_info_id = "";
      this.form.doc_date = "";
      this.form.doc_name = "";
      this.form.doc_detail = "";
      this.form.doc_form = "";
      this.form.file = false;
    },
  },
};
</script>